import "../../../styles/news.css";
import React from "react";
import api from "../../../services/api";
import { imagemURL, countNewsHome } from "../../../services/variables";
import Loading from "../others/Loading";
import SubTitle from "../others/title/SubTitle";

export default class News extends React.Component {
  state = {
    newsLast: [],
    removeLoading: false,
  };
  componentDidMount() {
    api
      .get(`/searchLast/${countNewsHome}`)
      .then((res) => {
        const result = res.data;
        if (result.err) {
        } else {
          //console.log(result);
          this.setState({ newsLast: result.res, removeLoading: true });
        }
      })
      .catch((err) =>
        /* alert("Ocorreu um erro, tente novamente ! DB") */ console.log(
          "Ocorreu um erro, tente novamente"
        )
      );
  }

  render() {
    return (
      <>
        {/* Rota Principal */}
        <SubTitle subTitle={"Notícias"} border={true} />

        {!this.state.removeLoading && <Loading />}
        <section className="section-container fundo_svg">
          <div className="container-news">
            <LOA2 />
            <LOA />
          </div>
        </section>
        {this.state.newsLast.length != 0 ? (
          <section className="section-container fundo_svg">
            <div className="container-news">
              {this.state.newsLast != undefined ? (
                <>
                  {this.state.newsLast.map((news, i) => (
                    <div className="container-card-news " key={i}>
                      <a
                        className="card-link-news-row link-news-card"
                        href={"/noticia/" + news.ID}
                      >
                        <div className="div-card-img">
                          <img
                            alt="imagem da notícia"
                            src={
                              news.IMG == "logo.jpg"
                                ? "/imagens/logo.png"
                                : imagemURL + news.IMG
                            }
                            className="card-img"
                          />
                        </div>
                        <div className="card-link-news-row">
                          <div className="card-txt">
                            <h6>{news.TITULO}</h6>
                          </div>
                          <div className="news-body">
                            <p>{news.CORPO_TEXTO}</p>
                          </div>
                          <div className="txt-views link-news-card">
                            <div className="category-nick">
                              <p>{news.CATEGORIA}</p>
                            </div>

                            <div className="card-views">
                              <p>{news.VISUALIZACAO}</p>
                              <img
                                alt="views"
                                src="/icons/views.svg"
                                className="icon-views"
                              />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </section>
        ) : (
          <>
            <br />
            <br />
          </>
        )}
      </>
    );
  }
}

let LOA = () => {
  return (
    <div className="container-card-news">
      <a
        className="card-link-news-row link-news-card"
        href={"/noticia/ed634b6d-5c6e-4a33-b53e-edfe2e2c5517"}
      >
        <div className="div-card-img">
          <img
            alt="imagem da notícia"
            src="https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/4f1cf16edf5d73feaad4fec2a03c7c9e1cf536aa_23df6906-f0a5-4d65-a347-d5758125a4df.jpg"
            className="card-img"
          />
        </div>
        <div className="card-link-news-row">
          <div className="card-txt">
            <h6>CONSULTA PÚBLICA ONLINE - LOA 2025</h6>
          </div>
          <div className="news-body">
            <p>
              A Prefeitura de Nova Iorque (MA) apresenta aos munícipes à
              consulta pública para a elaboração da Lei Orçamentária Anual
              (LOA–2025).
            </p>
          </div>
          <div className="txt-views link-news-card">
            <div className="category-nick">
              <p>Geral</p>
            </div>
            <div className="card-views">
              <p>0</p>
              <img alt="views" src="/icons/views.svg" className="icon-views" />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};
let LOA2 = () => {
  return (
    <div className="container-card-news">
      <a
        className="card-link-news-row link-news-card"
        // href={"/noticia/4500fc12-7b11-44be-95ca-4f7eb5f95aab"}
        href={
          "https://docs.google.com/forms/d/e/1FAIpQLSeuOBJaA0cdDiCaF1io-KqZ6cwzIfptbD68hRWgQpV_KUAEYw/viewform?usp=sf_link"
        }
        target="_blank"
      >
        <div className="div-card-img">
          <img
            alt="imagem da notícia"
            src="https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/4f1cf16edf5d73feaad4fec2a03c7c9e1cf536aa_df0dd714-bc5c-41e2-9e8a-f9222ce29afd.png"
            className="card-img"
          />
        </div>
        <div className="card-link-news-row">
          <div className="card-txt">
            <h6>Utilidade Pública - LOA 2025</h6>
          </div>
          <div className="news-body">
            <p>
              A Prefeitura de Nova Iorque (MA) apresenta aos munícipes à
              consulta pública para a elaboração da Lei Orçamentária Anual
              (LOA–2025).
            </p>
          </div>
          <div className="txt-views link-news-card">
            <div className="category-nick">
              <p>Geral</p>
            </div>
            <div className="card-views">
              <p>0</p>
              <img alt="views" src="/icons/views.svg" className="icon-views" />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};
